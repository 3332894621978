import {
  Alert,
  ConfirmModal,
  Text,
  showToast,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getGetProjectsProjectIdRunsRunIdQueryKey,
  usePostProjectsProjectIdRunsRunIdUnaccept,
} from "../../../.rest-hooks/runs";
import { getGetTreatmentareasQueryKey } from "../../../.rest-hooks/treatment-areas";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";

interface UnacceptMultipleScenarioRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  runId: string;
  runName: string;
}

const UnacceptMultipleScenarioRunModal = ({
  isOpen,
  onClose,
  projectId,
  runId,
  runName,
}: UnacceptMultipleScenarioRunModalProps) => {
  const [acceptRunError, setAcceptRunError] = useState<AlertContent | null>(
    null,
  );

  const queryClient = useQueryClient();

  const { mutateAsync: unacceptScenarioRun } =
    usePostProjectsProjectIdRunsRunIdUnaccept({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsRunIdQueryKey(
              projectId,
              runId,
            ),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsQueryKey(projectId),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetTreatmentareasQueryKey({ projectId }),
          });

          showToast({
            title: "Acceptance removed",
            variant: "success",
          });
        },
        onError: () => {
          showToast({
            message: DEFAULT_RMP_ERROR_MESSAGE,
            title: "Unable to remove acceptance",
            variant: "error",
          });
        },
      },
    });

  const onConfirm = async () => {
    try {
      return await unacceptScenarioRun({ projectId, runId });
    } catch (error) {
      setAcceptRunError({
        message: "Try again.",
        title: "Unable to remove acceptance",
      });
      throw error;
    }
  };

  const onConfirmSuccess = () => {
    onClose();
  };

  return (
    <ConfirmModal
      confirmLabel="Remove Acceptance"
      error={
        acceptRunError && (
          <Alert title={acceptRunError.title} variant="error">
            {acceptRunError.message}
          </Alert>
        )
      }
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      onConfirmSuccess={onConfirmSuccess}
      title={`Remove Acceptance on ${runName}`}
    >
      <Text size="bodyDefault">
        By removing the Acceptance on this run, you acknowledge it is no longer
        an accurate representation of the scenario.
      </Text>
    </ConfirmModal>
  );
};

export default UnacceptMultipleScenarioRunModal;
