/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import type {
  AppBFMCResponse,
  AppProjectResponse,
  AppTreatmentAreaResponse,
  AppTreatmentAreasListResponse,
  GetTreatmentareasParams,
  JSONAPIErrorResponse,
  ServiceCreateTreatmentAreaInput,
  ServiceRejectTreatmentsInput
} from './types'




/**
 * Approve treatment areas for a BRG (`individual-treatment-comparison-brigade`) project.
This will mark all associated treatment areas as approved and automatically create and start runs.

 * @summary Approve treatment areas
 */
export const postProjectsProjectIdApprovetreatments = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/approvetreatments`,undefined,options
    );
  }



export const getPostProjectsProjectIdApprovetreatmentsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>, TError,{projectId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  postProjectsProjectIdApprovetreatments(projectId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdApprovetreatmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>>
    
    export type PostProjectsProjectIdApprovetreatmentsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Approve treatment areas
 */
export const usePostProjectsProjectIdApprovetreatments = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdApprovetreatments>>,
        TError,
        {projectId: string},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdApprovetreatmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reject treatment areas for a BRG (`individual-treatment-comparison-brigade`) project.
This will update project notes, set the project activity to "Rejected" and archive the project.
Internally, this also marks the project as rejected (not exposed directly). This flag will be used when calculating whether a user is able to create a new BRG project or whether they have exceeded their quota.

 * @summary Reject treatment areas
 */
export const postProjectsProjectIdRejecttreatments = (
    projectId: string,
    serviceRejectTreatmentsInput: ServiceRejectTreatmentsInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.post(
      `/projects/${projectId}/rejecttreatments`,
      serviceRejectTreatmentsInput,options
    );
  }



export const getPostProjectsProjectIdRejecttreatmentsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>, TError,{projectId: string;data: ServiceRejectTreatmentsInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>, TError,{projectId: string;data: ServiceRejectTreatmentsInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>, {projectId: string;data: ServiceRejectTreatmentsInput}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postProjectsProjectIdRejecttreatments(projectId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdRejecttreatmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>>
    export type PostProjectsProjectIdRejecttreatmentsMutationBody = ServiceRejectTreatmentsInput
    export type PostProjectsProjectIdRejecttreatmentsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Reject treatment areas
 */
export const usePostProjectsProjectIdRejecttreatments = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>, TError,{projectId: string;data: ServiceRejectTreatmentsInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdRejecttreatments>>,
        TError,
        {projectId: string;data: ServiceRejectTreatmentsInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdRejecttreatmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of treatment areas.
 * @summary Get a list of treatment areas
 */
export const getTreatmentareas = (
    params: GetTreatmentareasParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppTreatmentAreasListResponse>> => {
    
    return axios.get(
      `/treatmentareas`,{
    ...options,
        params: {...params, ...options?.params},
        paramsSerializer: (params) => qs.stringify(params, {"arrayFormat":"comma"}),}
    );
  }


export const getGetTreatmentareasQueryKey = (params: GetTreatmentareasParams,) => {
    return [`/treatmentareas`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTreatmentareasQueryOptions = <TData = Awaited<ReturnType<typeof getTreatmentareas>>, TError = AxiosError<JSONAPIErrorResponse>>(params: GetTreatmentareasParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTreatmentareasQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTreatmentareas>>> = ({ signal }) => getTreatmentareas(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTreatmentareasQueryResult = NonNullable<Awaited<ReturnType<typeof getTreatmentareas>>>
export type GetTreatmentareasQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetTreatmentareas<TData = Awaited<ReturnType<typeof getTreatmentareas>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: GetTreatmentareasParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTreatmentareas>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTreatmentareas<TData = Awaited<ReturnType<typeof getTreatmentareas>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: GetTreatmentareasParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTreatmentareas>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTreatmentareas<TData = Awaited<ReturnType<typeof getTreatmentareas>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: GetTreatmentareasParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a list of treatment areas
 */

export function useGetTreatmentareas<TData = Awaited<ReturnType<typeof getTreatmentareas>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: GetTreatmentareasParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareas>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTreatmentareasQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a treatment area.
 * @summary Create a treatment area
 */
export const postTreatmentareas = (
    serviceCreateTreatmentAreaInput: ServiceCreateTreatmentAreaInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCResponse>> => {
    
    return axios.post(
      `/treatmentareas`,
      serviceCreateTreatmentAreaInput,options
    );
  }



export const getPostTreatmentareasMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTreatmentareas>>, TError,{data: ServiceCreateTreatmentAreaInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postTreatmentareas>>, TError,{data: ServiceCreateTreatmentAreaInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTreatmentareas>>, {data: ServiceCreateTreatmentAreaInput}> = (props) => {
          const {data} = props ?? {};

          return  postTreatmentareas(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostTreatmentareasMutationResult = NonNullable<Awaited<ReturnType<typeof postTreatmentareas>>>
    export type PostTreatmentareasMutationBody = ServiceCreateTreatmentAreaInput
    export type PostTreatmentareasMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Create a treatment area
 */
export const usePostTreatmentareas = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTreatmentareas>>, TError,{data: ServiceCreateTreatmentAreaInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postTreatmentareas>>,
        TError,
        {data: ServiceCreateTreatmentAreaInput},
        TContext
      > => {

      const mutationOptions = getPostTreatmentareasMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific treatment area.
 * @summary Get a specific treatment area
 */
export const getTreatmentareasTreatmentAreaId = (
    treatmentAreaId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppTreatmentAreaResponse>> => {
    
    return axios.get(
      `/treatmentareas/${treatmentAreaId}`,options
    );
  }


export const getGetTreatmentareasTreatmentAreaIdQueryKey = (treatmentAreaId: string,) => {
    return [`/treatmentareas/${treatmentAreaId}`] as const;
    }

    
export const getGetTreatmentareasTreatmentAreaIdQueryOptions = <TData = Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError = AxiosError<JSONAPIErrorResponse>>(treatmentAreaId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTreatmentareasTreatmentAreaIdQueryKey(treatmentAreaId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>> = ({ signal }) => getTreatmentareasTreatmentAreaId(treatmentAreaId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(treatmentAreaId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTreatmentareasTreatmentAreaIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>>
export type GetTreatmentareasTreatmentAreaIdQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetTreatmentareasTreatmentAreaId<TData = Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 treatmentAreaId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTreatmentareasTreatmentAreaId<TData = Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 treatmentAreaId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTreatmentareasTreatmentAreaId<TData = Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 treatmentAreaId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a specific treatment area
 */

export function useGetTreatmentareasTreatmentAreaId<TData = Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 treatmentAreaId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTreatmentareasTreatmentAreaId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTreatmentareasTreatmentAreaIdQueryOptions(treatmentAreaId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



