import { QueryBoundary } from "@kablamo/kerosene-ui/react-query";
import {
  Alert,
  EllipsisVertical,
  IconButton,
  OverflowMenu,
  Spinner,
  type IconButtonSize,
} from "@kablamooss/geo-ds-core-components";
import compact from "lodash/compact";
import type { Key } from "react";
import { useBoolean } from "usehooks-ts";
import { useGetProjectsProjectId } from "../../../.rest-hooks/projects";
import {
  AppRunStatus,
  type ServiceProjectTypeAttribute,
} from "../../../.rest-hooks/types";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import {
  getProjectRunAcceptanceLabel,
  getProjectRunHasAcceptanceAction,
  isStatewideProject,
  isTreatmentComparisonProject,
  type ProjectParentHref,
} from "../../../config/projects";
import { useHasProjectPrivilege } from "../../../hooks/useHasProjectPrivilege";
import type { Run } from "../../../types";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";
import AuthoriseRunModal from "../../statewide/Runs/AuthoriseRun/AuthoriseRunModal";
import AcceptScenarioRunModal from "../AcceptScenarioRun/AcceptScenarioRunModal";
import CloneScenarioRunModal from "../CloneScenarioRun/CloneScenarioRunModal";

interface ScenarioRunOverflowMenuProps {
  hasAcceptRun?: boolean;
  parentHref: ProjectParentHref;
  projectId: string;
  projectType: ServiceProjectTypeAttribute;
  run: Run;
  size?: IconButtonSize;
}

const ScenarioRunOverflowMenu = ({
  hasAcceptRun = false,
  parentHref,
  projectId,
  projectType,
  run,
  size = "sm",
}: ScenarioRunOverflowMenuProps) => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();
  const {
    setTrue: openAcceptRun,
    setFalse: closeAcceptRun,
    value: isAcceptRunOpen,
  } = useBoolean();

  const projectQuery = useGetProjectsProjectId(projectId, {
    query: {
      enabled: !!projectId && isOpen,
      select: (response) => deserializeJsonApi(response.data),
    },
  });

  const { data: canCloneRun } = useHasProjectPrivilege(projectId, {
    requestedPermissions: ["clone-run"],
  });
  const { data: canAcceptRun } = useHasProjectPrivilege(projectId, {
    requestedPermissions: ["accept-run"],
  });

  const runAcceptanceLabel = getProjectRunAcceptanceLabel(
    projectQuery.data?.data.type,
  );

  const impactAnalysisRunItems = compact([
    !isTreatmentComparisonProject(run.project.type) && {
      key: "clone",
      label: "Clone run",
      disabled: !canCloneRun,
    },
    hasAcceptRun &&
      run.type === "PhoenixPostprocessing" &&
      run.accepted === false &&
      run.status === "Complete" && {
        key: "accept",
        label: `${runAcceptanceLabel}`,
        disabled:
          !canAcceptRun ||
          !getProjectRunHasAcceptanceAction({
            projectType,
            scenario: run.scenario,
            status: run.status,
            qaStatus: run.qaStatus,
          }),
      },
  ]);

  const onAction = (key: Key) => {
    switch (key) {
      case "clone":
        open();
        break;
      case "accept":
        openAcceptRun();
        break;
      default:
        break;
    }
  };

  const isDisabled =
    run.status === AppRunStatus.In_setup ||
    run.archived ||
    impactAnalysisRunItems.length === 0;

  return (
    <>
      <OverflowMenu
        disabled={isDisabled}
        disabledKeys={impactAnalysisRunItems
          .filter((item) => item.disabled)
          .map((item) => item.key)}
        items={impactAnalysisRunItems}
        onAction={(key: Key) => {
          onAction(key);
        }}
        renderTrigger={(triggerProps) => (
          <IconButton
            {...triggerProps}
            disabled={isDisabled}
            icon={EllipsisVertical}
            label="Actions"
            variant="secondary"
            size={size}
          />
        )}
      />
      <QueryBoundary
        query={projectQuery}
        errorFallback={
          <Alert variant="error" title="Unable to load runs">
            {DEFAULT_RMP_ERROR_MESSAGE}
          </Alert>
        }
        loadingFallback={<Spinner />}
      >
        {({ data: { data: project } }) => {
          const isStatewide = isStatewideProject(project.type);

          return (
            <>
              <CloneScenarioRunModal
                isOpen={isOpen}
                onClose={close}
                parentHref={parentHref}
                projectId={run.project.id}
                projectType={project.type}
                run={run}
              />
              {isStatewide ? (
                <AuthoriseRunModal
                  isOpen={isAcceptRunOpen}
                  onClose={closeAcceptRun}
                  projectId={run.project.id}
                  projectType={run.project.type}
                  runId={run.id}
                  runName={run.name}
                />
              ) : (
                <AcceptScenarioRunModal
                  isOpen={isAcceptRunOpen}
                  onClose={closeAcceptRun}
                  projectId={run.project.id}
                  runId={run.id}
                  runName={run.name}
                  scenario={run.scenario}
                />
              )}
            </>
          );
        }}
      </QueryBoundary>
    </>
  );
};

export default ScenarioRunOverflowMenu;
